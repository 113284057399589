form.baseline-form {
  margin-top: 65px;
}

.form-divider {
  max-width: 1024px;
  margin-left: 0;
}

.form-header {
  color: var(--primary-color);
  margin: 30px 0 15px;
  max-width: 1024px;
  border-bottom: solid 1px #dddddd;
  padding: 10px 5px;

  &.with-button {
    display: flex;
    justify-content: space-between;
    max-width: 1024px;
    margin-right: 40px;

    h4 {
      flex-grow: 3;
      padding: 0;
      align-self: center;
    }

    .icon-btn {
      align-self: flex-end;
      flex-grow: 0;
      margin-right: 0 !important;
    }
  }
}

.input-entry {
  display: block;
  float: none;
  margin: 17px;
}

.expandable-header {
  color: #461e7d;
  cursor: pointer;

  span {
    vertical-align: middle;
  }

  .mat-icon {
    vertical-align: middle;
    line-height: 25px;
  }
}

section {
  max-width: 1000px;
  margin: 0 auto;
  color: #717171;
}

.form-field-wrapper-col {
  width: 100%;
  height: 70px;
  font-weight: bold;
  margin: 0;
  padding: 0 20px 0 0;
  position: relative;
}

.form-field-wrapper {
  width: 33%;
  height: 70px;
  font-weight: bold;
  margin: 0;
  padding: 0 20px 0 0;
  position: relative;

  &:hover {
    bmd-inline-edit {
      .mat-icon {
        display: block;
      }
    }
  }

  bmd-inline-edit {
    overflow: hidden;
    display: flex;
    max-width: 100%;

    .mat-mdc-form-field {
      display: contents;
    }

    font-weight: normal;

    .mat-icon {
      fill: var(--primary-color);
      color: var(--primary-color);
      display: none;
    }
  }

  .field-value {
    font-weight: normal;
  }
}

@media screen and (max-width: 768px) {
  .form-field-wrapper {
    width: 100%;
  }
  .input-entry {
    display: block;
    float: none;
    margin: 17px;
  }
}

@media screen and (max-width: 1200px) {
  .form-field-wrapper {
    width: 50%;
  }
  .input-entry {
    display: block;
    float: none;
    margin: 17px;
  }
}
