@import 'utility/variables';

button.toggle-sidenav {
  height: 50px;
  width: 50px;
  line-height: 50px;
  background-color: transparent;
  border: none;
  color: var(--primary-color);
  &:hover {
    cursor: pointer;
    color: var(--primary-color);
    opacity: .5;
  }
  &:active,
  &:focus {
    color: var(--primary-color);
  }
}

.sidenav-component-container {
  display: none;
}

.sidenav-header {
  padding: 18px 5px 17px 25px;

  // background: #f2f0f9;
  .left-column {
    width: 300px;

    .page-title {
      line-height: 30px;
    }
  }

  .right-column {
    padding: 0;
  }
}

.mat-drawer-opened {
  overflow: auto !important;

  .sidenav-header {
    padding-right: 20px;
    border-bottom: 1px solid $primary-color;

    .toggle-sidenav {
      transform: rotate(180deg);
    }
  }

  .sidenav-component-container {
    display: block;
    height: calc(100% - 85px);
    overflow-y: auto;

    // background-color: #f2f0f9;
  }
}

.sidenav-container {
  width: $sidenav-width;
  overflow: visible !important;

  .mat-drawer-inner-container {
    overflow: visible;
  }
}

.mat-drawer-container {
  background-color: white !important;
}

.mat-drawer {
  visibility: visible !important;
  display: block !important;
  background: white;
}

.mat-sidenav-content {
  overflow: unset !important;
  background: white;
  height: 100%;
}

@media screen and (max-width: 768px) {
  button.toggle-sidenav {
    height: 40px;
    line-height: 40px;
  }

  .mat-drawer-opened {
    width: 100%;

    button.toggle-sidenav {
      height: 50px;
      line-height: 50px;
    }
  }

  .sidenav-header {
    padding: 6px 6px 6px 25px;
  }

  .mat-sidenav-content {
    margin-left: 0;
  }
}
