/*** Color ***/

$primary-color: var(--primary-color);
$primary-lighter-color: #ddd7ea;
$background-color: #b2ffb2;
$warn-lighter-color: #fd6297;
$warn-darker-color: #fd6297;
$alert-success-color: #1ea633;
$alert-failure-color: #ffe3ed;
$background-grey: #f2f2f2;
$border-grey: #d9d9d9;

// $warn-lighter-color: var(--warn-lighter-color);
// $primary-lighter-color: var(--primary-lighter-color);

/*** Text Color ***/

$text-light: #717171;

/***************   */

/*** Sidenav ***/

$sidenav-dock-width: 60px;
$sidenav-width: 400px;

/*** Button ***/

$button-hover: #fcfaff;

/*** Font ***/

$font: var(--font);
