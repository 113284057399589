@import 'utility/variables';

.x-btn-primary,
.x-btn-secondary {
  padding: 0 14px;
  margin: unset;
  border: solid 1px var(--primary-text-color);
}

.x-btn-primary {
  background: var(--primary-color);
  &:hover,
  &:active,
  &:focus{
    background: var(--primary-color);
    opacity:.5;
  }
}

.x-btn-secondary {
  background: var(--secondary-color);
  color: var(--primary-text-color);
  &:hover,
  &:active,
  &:focus{
    background: var(--secondary-color);
    opacity:.5;
  }
}

button {
  background: var(--secondary-color);
  color: var(--primary-text-color);
  &:hover,
  &:focus,
  &:active {
    color: var(--primary-text-color);
  }
}

// @import 'mixins';
@include x-icon-button;
.mat-mdc-outlined-button {
  border: 1px solid !important;
}

.icon-btn {
  font-weight: normal !important;
  font-size: 1.125rem;
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  .mat-button-wrapper > * {
    display: inline-block;
  }

  .btn-spinner {
    margin-right: 5px;

    circle {
      stroke: white;
    }
  }
}

.action-btn {
  .mat-icon {
    color: $primary-color;
  }
}

.mat-mdc-outlined-button {
  border: 1px solid !important;
}

.primary-btn {
  border-color: $primary-color;
  background-color: #fff;
  color: $primary-color;
  font-weight: normal;
  font-size: 0.8rem !important;

  &:hover {
    background-color: $button-hover;
  }
}

.primary-btn[disabled],
.primary-btn:disabled {
  border-color: #bbb !important;
  background-color: #aaa !important;
}

.secondary-btn {
  color: $primary-color !important;
  border-color: rgb(70 30 125 / 60%) !important;
  background-color: #fff !important;
  font-weight: normal !important;
  font-size: 13px !important;

  &:hover {
    background-color: rgb(70 30 125 / 20%) !important;
  }
}

.wide-btn {
  padding: 0 30px !important;
}

.tooltip-btn-wrapper {
  display: inline-block;
}

.mat-mdc-tooltip {
  background: rgb(0 0 0 / 70%);
  font-size: 13px;
}
